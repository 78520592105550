import React from "react";
import { Link } from "gatsby";
import Helmet from 'react-helmet';
import Layout from '../components/layout';
import favicon from '../images/favicon.ico';

// components
import Navbar from '../components/navbar/navbar';
import Footer from '../components/footer/footer';
import Article from '../components/article/article';
import ContactSection from "../components/contact/contact";

// images
import img_text_section from "../images/vector/vector-starta-bolag.svg";


function Startabolag() {
    return (
        <Layout>
            <Helmet>
                <title>Excendo Redovisning AB</title>
                <link rel="icon" type="image/png" href={favicon} sizes="16x16" />
                <meta name="description" content="" />
                <meta name="keywords" content="" />
                <meta property="og:title" content="Excendo Redovisning AB" />
                <meta property="og:tyope" content="website" />
                <meta property="og:discription" content="" />
                <meta property="og:image" content=" " />
                <meta property="og:locale" content="sv_SE" />
                <meta property="og:url" content="http://www.excendo.se/" />
                <link rel="cannonical" href="http://www.excendo.se/" />
            </Helmet>

            <Navbar whitemode={true} />


            <section className="-mt-20">
                {/*-------------- hero section - START ---------------*/}
                <div className="w-screen bg-startabolag-hero bg-center bg-no-repeat bg-cover">
                    <div className="custome_overlay flex justify-center items-center py-48">
                        <h1 className="text-4xl font-semibold text-white text-center">Starta Bolag</h1>
                    </div>
                </div>
                {/*-------------- hero section - END ---------------*/}
            </section>



            <section>
                {/*-------------- text section - START ---------------*/}
                <Article
                    image={img_text_section}
                    image_alt=""
                    heading="Starta bolag"
                    title="Har Du tankar på att starta bolag?"
                >
                    <p>
                        Oftast finns en affärsidé men det saknas 
                        kunskap om hur man går vidare.
                        <br/><br/>
                        Vi hjälper Dig starta företaget oavsett vilken företagsform Du väljer, 
                        enskild firma, handelsbolag, kommanditbolag eller aktiebolag. 
                        Är Du osäker på vilken företagsform som bäst passar 
                        Dig och Ditt företag så hjälper vi Dig att reda ut 
                        begreppen samt fördelarna och nackdelarna för respektive företagsform.
                        <br/><br/>
                        Att ha en affärsplan samt en resultat- och likviditetsbudget 
                        för att se hur bolaget eventuellt  kommer att utvecklas 
                        under de första månaderna rekommenderas, 
                        kontakta oss så går vi igenom det tillsammans.
                        <br/><br/>
                        Är starten brådskande assisterar vi med köp av lagerbolag.
                    </p>
                </Article>
                {/*-------------- text section - END ---------------*/}
            </section>


            <div className="container mx-auto px-4 xl:px-24">
                <hr className="border-1 border-lightgray-excendo"></hr>
            </div>



            <section className="my-32">
                {/*-------------- other services - START ---------------*/}
                <div className="container mx-auto px-4 xl:px-24">
                    <div className="grid grid-cols-1 md:grid-cols-3 gap-20">
                        <div className="px-8 md:px-0">
                            <div className="bg-red-100 rounded-full w-16 h-16 flex justify-center items-center text-orange-excendo shadow-2xl">
                                <svg className="h-8 w-8" viewBox="0 0 20 20" fill="currentColor" >
                                    <path stroke="currentColor" stroke-width="2" d="M18.4146+18.7591L1.61672+18.7591L1.61672+2.35182" fill="none" stroke-linecap="round" opacity="1" stroke-linejoin="round"/>
                                    <path d="M9.82035+19.1497L7.47646+19.1497L7.47646+8.99285L9.82035+8.99285L9.82035+19.1497ZM6.69516+19.1497L4.35127+19.1497L4.35127+11.7274L6.69516+11.7274L6.69516+19.1497ZM3.56997+19.1497L1.22607+19.1497L1.22607+14.0713L3.56997+14.0713L3.56997+19.1497ZM14.7035+6.25831C13.5186+6.25831+12.5549+5.29458+12.5549+4.10974C12.5549+2.9249+13.5186+1.96117+14.7035+1.96117C15.8883+1.96117+16.852+2.9249+16.852+4.10974C16.852+5.29458+15.8883+6.25831+14.7035+6.25831ZM16.6567+6.64896L12.7502+6.64896C11.5654+6.64896+10.6017+7.61269+10.6017+8.79753L10.6017+13.29C10.6017+13.7213+10.9521+14.0713+11.383+14.0713C11.8138+14.0713+12.1642+13.7213+12.1642+13.29L12.1642+9.95815C12.1642+9.85228+12.2537+9.76282+12.3596+9.76282C12.4654+9.76282+12.5549+9.85228+12.5549+9.95815L12.5549+18.095C12.5549+18.706+12.8811+19.1497+13.4925+19.1497C14.0714+19.1497+14.5081+18.6962+14.5081+18.095L14.5081+14.0713C14.5081+13.9635+14.5956+13.876+14.7035+13.876C14.8113+13.876+14.8988+13.9635+14.8988+14.0713L14.8988+18.209C14.9004+18.2114+14.9035+18.213+14.905+18.2153C14.9586+18.7556+15.3746+19.1497+15.9145+19.1497C16.5255+19.1497+16.852+18.706+16.852+18.095L16.852+10.0261C16.852+9.92025+16.9415+9.8308+17.0474+9.8308C17.1532+9.8308+17.2427+9.92025+17.2427+10.0261L17.2427+13.29C17.2427+13.7213+17.5931+14.0713+18.024+14.0713C18.4549+14.0713+18.8053+13.7213+18.8053+13.29L18.8053+8.79753C18.8053+7.61269+17.8416+6.64896+16.6567+6.64896Z" opacity="1" fill="currentColor"/>
                                </svg>
                            </div>
                            <h2 className="uppercase mt-6 text-orange-excendo font-medium mb-3">Rådgivning</h2>
                            <p className="font-light text-sm text-gray-500 mb-3">
                                Våra auktoriserade konsulter erbjuder expertrådgivning i det mesta kring företagande och mer avancerade bolagsärenden. 
                            </p>
                            <Link to="/radgivning" className="text-orange-excendo flex items-center hover:text-red-600">
                                Läs mer
                                <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20"  fill="currentColor">
                                    <path fill-rule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clip-rule="evenodd" />
                                </svg>
                            </Link>
                        </div>
                        
                        <div className="px-8 md:px-0">
                            <div className="bg-blue-100 rounded-full w-16 h-16 flex justify-center items-center text-blue-excendo shadow-2xl">
                                <svg className="h-8 w-8" viewBox="0 0 20 20" fill="currentColor" >
                                    <path d="M0.328759+19.6385L0.328759+14.7839L3.56513+14.7839L3.56513+19.6385L0.328759+19.6385ZM4.37423+19.6385L4.37423+13.1657L7.6106+13.1657L7.6106+19.6385L4.37423+19.6385ZM8.41969+19.6385L8.41969+10.7385L11.6561+10.7385L11.6561+19.6385L8.41969+19.6385ZM12.4652+19.6385L12.4652+12.3566L15.7015+12.3566L15.7015+19.6385L12.4652+19.6385ZM16.5106+19.6385L16.5106+9.12027L19.747+9.12027L19.747+19.6385L16.5106+19.6385Z" opacity="1" fill="currentColor"/>
                                    <path stroke="currentColor" stroke-width="1.6" d="M1.94695+9.03936L5.99241+6.69299L10.0379+3.45662L14.0833+5.8839L18.1288+1.83843" fill="none" stroke-linecap="round" opacity="1" stroke-linejoin="round"/>
                                    <path d="M1.94695+7.50209C1.05325+7.50209+0.328759+8.22657+0.328759+9.12027C0.328759+10.014+1.05325+10.7385+1.94695+10.7385C2.84065+10.7385+3.56513+10.014+3.56513+9.12027C3.56513+8.22657+2.84065+7.50209+1.94695+7.50209ZM5.99241+5.07481C5.09871+5.07481+4.37423+5.79929+4.37423+6.69299C4.37423+7.58669+5.09871+8.31118+5.99241+8.31118C6.88611+8.31118+7.6106+7.58669+7.6106+6.69299C7.6106+5.79929+6.88611+5.07481+5.99241+5.07481ZM10.0379+1.83843C9.14418+1.83843+8.41969+2.56292+8.41969+3.45662C8.41969+4.35032+9.14418+5.07481+10.0379+5.07481C10.9316+5.07481+11.6561+4.35032+11.6561+3.45662C11.6561+2.56292+10.9316+1.83843+10.0379+1.83843ZM14.0833+4.26571C13.1896+4.26571+12.4652+4.9902+12.4652+5.8839C12.4652+6.7776+13.1896+7.50209+14.0833+7.50209C14.977+7.50209+15.7015+6.7776+15.7015+5.8839C15.7015+4.9902+14.977+4.26571+14.0833+4.26571ZM18.1288+0.220246C17.2351+0.220246+16.5106+0.944732+16.5106+1.83843C16.5106+2.73213+17.2351+3.45662+18.1288+3.45662C19.0225+3.45662+19.747+2.73213+19.747+1.83843C19.747+0.944733+19.0225+0.220246+18.1288+0.220246Z" opacity="1" fill="currentColor"/>
                                </svg>
                            </div>
                            <h2 className="uppercase mt-6 text-blue-excendo font-medium mb-3">Controllerfunktion</h2>
                            <p className="font-light text-sm text-gray-500 mb-3">
                            Vi arbetar med företag som kanske redan har en ekonomiavdelning men som också har en styrelse och vd som kräver mer än en avstämd resultat- och balansrapport vid månadsslutet.
                            </p>
                            <Link to="/controllerfunktion" className="text-blue-excendo flex items-center hover:text-blue-600">
                                Läs mer
                                <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20"  fill="currentColor">
                                    <path fill-rule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clip-rule="evenodd" />
                                </svg>
                            </Link>
                        </div>
 
                        
                        <div className="px-8 md:px-0">
                            <div className="bg-red-100 rounded-full w-16 h-16 flex justify-center items-center text-orange-excendo shadow-2xl">
                                <svg className="h-8 w-8" viewBox="0 0 20 20" fill="currentColor" >
                                    <path stroke="currentColor" stroke-width="2" d="M2.21613+2.36034L2.21613+19.3603" fill="none" stroke-linecap="butt" opacity="1" stroke-linejoin="miter"/>
                                    <path d="M19.2161+14.3603L11.5981+14.3603L10.5981+12.3603L1.21613+12.3603L1.21613+1.36034L11.8341+1.36034L12.8341+3.36034L19.2161+3.36034L19.2161+14.3603Z" opacity="1" fill="currentColor"/>
                                </svg>
                            </div>
                            <h2 className="uppercase mt-6 text-orange-excendo font-medium mb-3">Delgivningsmottagare</h2>
                            <p className="font-light text-sm text-gray-500 mb-3">
                                Excendo tillhandahåller en särskild delgivningsmottagare/delgivningsbar person samt utför administrativa tjänster åt bolag som saknar behörig ställföreträdare bosatt i Sverige.
                            </p>
                            <Link to="/delgivningsmottagare" className="text-orange-excendo flex items-center hover:text-red-600">
                                Läs mer
                                <svg className="h-5 w-5" viewBox="0 0 20 20"  fill="currentColor">
                                    <path fill-rule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clip-rule="evenodd" />
                                </svg>
                            </Link>
                        </div>
                
                    </div> 
                </div> 
                {/*-------------- other services - END ---------------*/}
            </section>

            <ContactSection />
            <Footer />
        </Layout>
    );
}

export default Startabolag;